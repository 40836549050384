@mixin footer-section-social {
  @include flex(1);
  a {
    color: transparent;
  }
  h2,
  .menu {
    color: $secondary-color;
    display: inline-block;
  }
  h2 {
    font-size: 1rem;
    color: $secondary-color;
  }
}

@mixin footer-section-contact {
  &:nth-of-child(1) {

  }
}

@mixin footer-section-links {
  &:nth-of-child(2) {
    
  }
}

@mixin footer-section-copyright {
  &:nth-child(3) {
    @include flex-basis(100%);
  }
}

@mixin footer-middle-sections {
  @include outer-container;
  @include display(flex);
  @include flex-flow(column wrap);
  @include media($medium) {
    @include flex-flow(row wrap);
  }
  section {
    padding: 2rem 0;
    color: #fff;
    @include flex(1);
    @include footer-section-contact;
    @include footer-section-links;
    @include footer-section-copyright;
    h2,
    a {
      color: $secondary-color;
    }
  }
}

#site-footer {
  ///////////////////////////////////////////////////////////////////////////////////
  $base-spacing: 1.5em !default;
  $base-accent-color: $primary-color !default;
  $medium-screen: em(640) !default;
  $large-screen: em(860) !default;

  ul:not(.contextual-links) {
    padding: 0;

    li {
      list-style: none;
      line-height: 1.5em;
    }
  }

  a {
    text-decoration: none;
  }
  //////////////////////////////////////////////////////////////////////////////////


  $footer-background: $base-accent-color;
  $footer-color: white;
  $footer-link-color: transparentize($footer-color, 0.6);
  $footer-disclaimer-color: transparentize($footer-color, 0.35);

  background: $footer-background;
  width: 100%;

  > section {
    padding: 1rem;
    @include media($xlarge) {
      padding: 0;
    }
  }
  .footer-top {
    margin-bottom: 1em;
    .footer-container {
      @include outer-container;
    }
  }

  .footer-logo {
    margin-bottom: 2em;
    text-align: center;

    img {
      height: 3em;
    }
  }

  .footer-middle {
    @include footer-middle-sections;
  }

  .footer-bottom {
    background: darken($footer-background, 5%);
    .footer-container {
      @include outer-container;
      @include display(flex);
        @include flex-flow(column nowrap);
      @include media($medium) {
        @include flex-flow(row nowrap);
      }
      section {
        padding: 1rem 0;
        @include flex(2);
        &.block-menu {
          @include footer-section-social;
        }
      }
      a {
        color: $secondary-color;
      }
      p {
        color: $footer-disclaimer-color;
        font-size: 0.9em;
        line-height: 1.5em;
        margin: auto;
      }
    }
  }

  .copyright {

  }

  .utility-menu {
    @include span-columns(8);

    ul {
      text-align: right;
      font-weight: 600;

      li {
        display: inline;
        padding-right: 1em;

        a {
          color: $footer-disclaimer-color;
        }
      }
    }
  }
}

