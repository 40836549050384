// Homepage Adpative Slick slideshow
@mixin slide-slogan {
	.view-slogan {
		padding: 2rem 1rem 2rem;
		bottom: 0;
		color: #fff;
		z-index: 1;
		left: 0;
		right: 0;
		width: 100%;
		background: $primary-color;
		@include media($medium) {
			padding: 4rem 1rem 6rem;
			position: absolute;
			background: linear-gradient(rgba(0,0,0, 0) 0%, rgba(0,0,0, .75) 100%);
		}
		.primary,
		.secondary {
			max-width: 40rem;
			margin: auto;
			text-transform: capitalize;
			text-align: center;
			font-weight: 700;
			text-shadow: 1px 1px rgba(0,0,0,.75);
		}
		.primary {
			@extend h1;
			@include media($medium) {
				font-size: 2.8rem;
			}
		}
		.secondary {
			@extend h2;
			@include media($medium) {
				font-size: 1.6rem;
			}
		}
	}
}

@mixin slick-controls {
	&.slick-prev,
	&.slick-next {
		opacity: 0;
		.view-content:hover & {
			opacity: .8;
		}
	}
}

@mixin slick-slide {
	&.slick-slide {
		height: 50vh;
		@include media($medium) {
			height: 90vh;
		}
	}
}

@mixin slick-slideshow {
	.slick-adp-slideshow {
		margin-bottom: 0;
		@include media($medium) {
			margin-bottom: 2rem;
		}
	}
}

@mixin adp-slick {
	.adp-inline {
		@include slick-slide;
	}
	.slick-arrow {
		@include slick-controls;
	}
	.view-slideshow.view-id-slideshow {
		position: relative;
		@include slide-slogan;
		@include slick-slideshow;
	}
}

// Banner images for inner page
.not-front {
	#banner-wrapper {
		@include outer-container;
	}
}