// Branding and colors
$client-orange: #f9a972;
$client-teal: #03848f;

$primary-color: $client-teal;
$secondary-color: $client-orange;

$menu-color: #fff;

// Site width
$site-width: 1280px;

// Breakpoints, use with @include media($breakpoint)
$small-screen: 0px;
$medium-screen: 640px;
$large-screen: 1024px;
$xlarge-screen: 1200px;
$xxlarge-screen: 1440px;

$small: new-breakpoint(min-width $small-screen);
$medium: new-breakpoint(min-width $medium-screen);
$large: new-breakpoint(min-width $large-screen);
$xlarge: new-breakpoint(min-width $xlarge-screen);
$xxlarge: new-breakpoint(min-width $xxlarge-screen);
// $xxxlarge...

