// Marinas variable
$marina-bg: #fff !default;

// Discover portal variable
$dcp-bg: $primary-color !default;
$dcp-link-height: 3rem !default;
$dcp-link-bg: rgba($dcp-bg, .75);

$lodging-bg: #e4833f !default;

// Icon portal variable
$ip-background: $primary-color !default;


// Marinas portal

@mixin marinas-portal-links-item {
	.link-image {
		position: relative;
		img {
			width: 100%;
		}
		&:before {
			content: ' ';
			width: 0;
			height: 0;
			border: 1rem solid transparent;
			border-bottom-color: white;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			z-index: 2;
		}
		@include media($medium) {
			@include flex(100%);
		}
	}
	.link-logo,
	.link-heading {
		text-align: center;
		@include media($medium) {
			text-align: left;
		}
	}
	.link-logo {
		margin: 1rem 1rem 0 0;
		height: 100px;
		width: 130px;
		img {
			width: 100%;
    		max-width: 150px;
		}
		@include media($medium) {
			@include flex(none);
		}
	}
	.link-heading {
		padding: 1rem;
		color: #666;
		font-size: 1.4rem;
		font-weight: 400;
		@include media($medium) {
			@include flex(1);
		}
	}
}

@mixin marinas-portal-title {
	.marinas-portal-title {
		@include flex(1);
		@include media($large) {
			@include flex(100%);
		}
		text-align: center;
		text-transform: uppercase;
		background: $secondary-color;
		color: #fff;
		font-size: 1.6rem;
		padding: 1.5rem 0;
		font-weight: 700;
	}
}

@mixin marinas-portal-links {
	.marinas-portal-links {
		padding: 1rem;
		+ .marinas-portal-links {
			@include media($large) {
				padding-left: 0;
			}
		}
		@include flex(1);
		@include media($medium) {
			@include display(flex);
			@include flex-flow(row wrap);
			@include justify-content(center);
		}
		@include marinas-portal-links-item;
	}
}

@mixin marinas-portal {
	.marinas-portal {
		background: $marina-bg;
		@include display(flex);
		@include align-items(stretch);
		@include flex-flow(column nowrap);
		@include media($large) {
			@include flex-flow(row wrap);
		}
		@include outer-container;
		@include marinas-portal-title;
		@include marinas-portal-links;
	}
}

// Discover portal
@mixin portal-sections {
	@include display(flex);
	@include flex-flow(column nowrap);
	.title,
	.links {
		text-align: center;
		@include display(flex);
		@include justify-content(center);
	}
	.title {
		@include flex(1);
		@include flex-flow(column nowrap);
		background: rgba(0,0,0,.125);
		.main,
		.sub {
			color: #fff;
			text-shadow: 1px 1px rgba(0,0,0,.5);
			@include flex(none);
		}
		.main {
			font-weight: 700;
			@include media($medium) {
				font-size: 2.4rem;
			}
		}
		.sub {
			font-weight: 400;
			line-height: 1.2;
			@include media($medium) {
				font-size: 1.8rem;
				margin: .5rem 0;
			}
		}
	}
	.links {
		@include flex(none);
		@include flex-flow(row wrap);

		> a {
			@include flex(1);
			@include flex-basis(50%);
			@include media($medium) {
				@include flex-basis(30%);
			}
			color: #fff;
			font-weight: 700;
			text-transform: uppercase;
			line-height: $dcp-link-height;
			background: $dcp-link-bg;
			white-space: nowrap;
			padding: .25rem;
			&:hover,
			&:focus {
				background: rgba($dcp-link-bg, 1);
			}
		}
	}
}

@mixin discover-portal-items {
	[class*="discover-portal-"] {
		@include flex(1);
		@include portal-sections;
		@include flex-basis(300px);
		padding: 1rem;
		margin: 0 0 1rem;
		background: $dcp-bg;
		background-size: cover;
	}
	.discover-portal-fishing {
		padding: 0;
	}
	.discover-portal-shopping {
		padding: 0;
		.title {
			padding: 1rem;
		}
	}
	.discover-portal-lodging {
		background: $lodging-bg;
		color: #fff;
		text-align: center;
		position: relative;
		&:before {
			content: ' ';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			background: url(../images/bg-lodging.jpg) center center no-repeat;
			background-size: cover;
			z-index: 0;
			opacity: .1;
		}
		.title {
			background: transparent;
		}
		#dhat-portal-form {
			position: relative;
			z-index: 2;
		}
		#dhat-portal-form > div{
			@include display(flex);
			@include justify-content(center);
			@include flex-flow(row wrap);
		}
		.container-inline-date {
			@include flex(1);
			@include flex-basis(49%);
			flex-basis: 49%;
			.form-item {
				display: block;
				width: 100%;
			}
			.date-padding {
				float: none;
			}
			&:nth-of-type(1) {
				padding-right: .25rem;
			}
			&:nth-of-type(2) {
				padding-left: .25rem;
			}
		}
		label,
		.description {
			display: none;
		}
		input.form-text {
			width: 100%;
			min-width: 5rem;
			border: none;
			color: #666;
		}
		input[type="submit"] {
			@include flex(none);
			@include align-self(center);
			background: $primary-color;
			border: 1px solid #fff;
			box-shadow: 0 4px 15px rgba(0,0,0,.25);
			&:hover,
			&:focus {
				background: lighten($primary-color, 15%);
			}
		}
	}
}

@mixin discover-portal-container {
	[class*="discover-portal-container-"] {
		@include flex(4);
		@include display(flex);
		@include align-items(stretch);
		@include flex-flow(column wrap);

		&:first-child {
			@include flex(6);
		}
		@include discover-portal-items;

		@include media($large) {
			&.discover-portal-container-left [class*="discover-portal-"] {
				margin: 0 1rem 1rem 0;
			}
			&.discover-portal-container-right [class*="discover-portal-"] {
				margin: 0 0 1rem 0;
			}
		}
	}
}

@mixin discover-portal {
	.discover-portal {
		@include display(flex);
		@include align-items(stretch);
		@include flex-flow(column nowrap);
		@include media($large) {
			@include flex-flow(row nowrap);
		}
		margin: 3rem auto 0;
		@include discover-portal-container;
		@include outer-container;
	}
}

@mixin icon-links {
	@include display(flex);
	@include align-content(center);
	@include flex-flow(column nowrap);
	[class*="icon-link-"] {
		@include flex(1);

	}
	.icon-link-image {
		@include flex-basis(45px);
		margin: 1rem;
	}
	.icon-link-title {
		@include flex-basis(1.2rem);
		font-weight: 500;
		font-size: 1.2rem;
	}
}

// Icon portal
@mixin icon-items {
	.icon-items {
		@include flex(1);
		@include flex-basis(50%);
		text-align: center;
		text-transform: uppercase;
		background: $ip-background;
		color: #fff;
		padding: 1rem;
		margin: .125rem;
		&:hover,
		&:focus {
			background: darken($ip-background, 15%);
		}
		@include media($large) {
			margin: 0 .125rem 0 0;
			&:last-child {
				margin-right: 0;
			}
		}
		@include icon-links;
	}
}

@mixin icon-portal {
	.icon-portal {
		@include display(flex);
		@include align-items(stretch);
		@include flex-flow(row wrap);
		@include media($large) {
			@include flex-flow(row nowrap);
		}
		@include icon-items;
		@include outer-container;
	}
}