$menu-color: #fff !default;
$top-bar-background: rgba($menu-color, .85) !default;

$top-bar-height-mobile: 4rem !default;
$top-bar-height-desktop: 6rem !default;

$offcanvas-width: 300px !default;

$offcanvas-font-size: 1.2rem !default;
$offcanvas-color: #fff !default;
$offcanvas-background: #666 !default;

$desktop-nav-color: $primary-color !default;

$scrim-background: rgba(#000, .75) !default;

// Toggle button styles
@mixin nav-toggle {
	.nav-toggle {
		display: block;
		height: $top-bar-height-mobile;
		max-width: $top-bar-height-mobile;
		z-index: 2;
		position: relative;
		@include media($large) {
			display: none;
		}
	}
	.nav-toggle-icon {
		display: inline-block;
		height: 100%;
		width: 100%;
		text-align: center;
		transform: scale(.5);
		&:before,
		&:after {
			content: ' ';
			display: inline-block;
			width: 100%;
			height: ($top-bar-height-mobile / 6);
			margin: ($top-bar-height-mobile / 6) 0 0;
			background: $desktop-nav-color;
		}
	}
}

// Nav container layout and style
@mixin nav-base {
	height: 100vh;
	z-index: 1
}

@mixin nav-offcanvas {
	background: $offcanvas-background;
	width: $offcanvas-width;
	top: 0;
	left: 0;
	position: fixed;
	overflow-y: scroll;
	padding: 1rem;
	@include transform(translateX(-100%));
}

@mixin nav-desktop {
	background: transparent;
	position: relative;
	width: 100%;
	height: auto;
	overflow: visible;
	padding: 0;
	@include transform(translateX(0));
	@include flex(1);
}

// Navigation items layout and style
@mixin nav-links-base {
	margin: 0;
	padding: 0;
	.link-wrapper {
		padding: 0;
		position: relative;
	}
	.sub-link-wrapper {
		padding: 0
	}
}

@mixin nav-links-touch {
	@at-root {
		.touchevents {
			.sub-links {
				li:first-child {
					display: inherit;
				}
			}
		}
	}
}

@mixin nav-links-offcanvas {
	font-size: $offcanvas-font-size;
	.sub-links {
		margin-left: 1rem;
		li:first-child {
			display: none;
		}
		a {
			line-height: $top-bar-height-desktop / 2;
		}
	}
	a {
		color: $offcanvas-color;
		text-transform: uppercase;
		font-size: .9rem;
		font-weight: 600;
		display: block;
		line-height: $top-bar-height-desktop / 2;
		@include media($large) {
			color: $desktop-nav-color;
		}
	}
}

@mixin nav-links-desktop {
	text-align: right;
	.top-link-wrapper {
		display: inline-block;
		height: $top-bar-height-desktop;
		line-height: $top-bar-height-desktop;
		&:hover,
		&:focus,
		&.active {
			.sub-links {
				display: block;
			}
		}
	}
	.link-wrapper {
		padding: 0;
		position: relative;
		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 0 1rem;
			white-space: nowrap;
			text-align: left;
		}
		> a:hover,
		> a:focus {
			background: darken($top-bar-background, 5%);
		}
	}
	.sub-links {
		border: 1px solid #ddd;
		background: rgba($top-bar-background, 1);
		position: absolute;
		top: $top-bar-height-desktop;
		right: 0;
		margin: 0;
		display: none;
		z-index: 1;
		min-width: 10rem;
		li {
			line-height: 1.4;
		}
	}
	a {
		line-height: inherit;
	}
}

@mixin navigation {
	// Navigation container
	.navigation {
		@include offcanvas-tranition;
		@include nav-base;
		@include nav-offcanvas;

		@include media($large) {
			@include nav-desktop;
		}
	}
	// Navigation links
	.nav-links {
		@include nav-links-base;
		@include nav-links-offcanvas;

		@include media($large) {
			@include nav-links-touch;
			@include nav-links-desktop;
		}

		
	}
}

@mixin offcanvas-tranition {
	transition: transform .3s ease, background .3s ease;
}

@mixin offcanvas-show {
	.offcanvas-show {
		@content;
	}
}
// Ofcanvas behaviors
@mixin offcanvas {
	.top-bar,
	.main-content {
		@include offcanvas-tranition;
		@include transform(translateX(0));
	}
	.not-front .page {
		padding-top: $top-bar-height-mobile;
		@include media($large) {
			padding-top: $top-bar-height-desktop;
		}
	}
	.offcanvas-show {
		width: 100%;
		height: 100vh;
		overflow: hidden;
	}
	@include offcanvas-show {
		.top-bar,
		.main-content {
			@include transform(translateX($offcanvas-width));
			@include media($large) {
				@include transform(translateX(0));
			}
		}
	}
}

@mixin scrim {
	// Content overlay
	.scrim {
		visibility: hidden;
		height: 0;
		width: 0;
		opacity: 0;
		transition: opacity .3s ease;
	}
	@include offcanvas-show {
		// Visible overlay
		.scrim {
			background: $scrim-background;
			position: fixed;
			width: 100%;
			height: 100vh;
			display: block;
			z-index: 2;
			opacity: 1;
			visibility: visible;
			top: 0;
			left: 0;
			@include media($large) {
				display: none;
			}
		}
	}
}

@mixin brand {
	.brand {
		position: relative;
		text-align: left;
		@include media($large) {
			max-width: 175px;
		}
		a {
			display: inline-block;
			padding: .5rem;
			@include media($large) {
				padding: 1rem;
			}
		}
		.logo {
			display: inline-block;
			width: auto;
			height: $top-bar-height-mobile - 1rem;
			max-width: none;
			@include media($large) {
				height: $top-bar-height-desktop - 2rem;
			}
		}
	}
}

@mixin top-bar {
	// Topbar
	.top-bar {
		background: $top-bar-background;
		height: $top-bar-height-mobile;
		.not-top & {
			background: rgba($top-bar-background, 1);
		}
		@include media($large) {
			height: $top-bar-height-desktop;
			box-shadow: 0 0 15px 0 rgba(0,0,0,.25);
		}
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 4;
		@include offcanvas-tranition;
		@include brand;
		.outer-wrapper {
			@include display(flex);
			@include media($medium) {
				height: $top-bar-height-desktop;
			}
		}
		.nav-toggle,
		.brand {
			@include flex(1);
			z-index: 2;
			position: relative;
		}
	}
	@include navigation;
	@include nav-toggle;
	@include offcanvas;
	@include scrim;
}