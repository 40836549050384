$social-icon-color: #fff !default;
$social-icon-size: 1.4rem !default;

// Map icon to domain
$social-icons-lists: (
	'facebook.com': 'fa-facebook',
	'twitter.com': 'fa-twitter',
	'pinterest.com': 'fa-pinterest',
	'plust.google.com': 'fa-google-plus'
);

section.block-menu {
	@each $domain, $icon-class in $social-icons-lists {
		a[href*="#{$domain}"] {
			color: transparent;
			font-size: 0px;
			width: $social-icon-size;
			height: $social-icon-size;
			padding: 0 .5rem;
			display: inline-block;
			@extend .fa;
			@extend ".#{$icon-class}";
			&:before {
				font-size: $social-icon-size;
				color: $social-icon-color;
			}
		}	
	}
}
