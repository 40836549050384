// Things to do full view
@mixin ttd-view-full-fields {
	.field-name-field-banner-image {
		background: #000;
		img {
			opacity: .75;
		}
	}
	.field-name-field-term-headline {
		color: $secondary-color;
	}
	.field-name-field-sidebar-image, {
		margin: 0 0 1rem;
		+ .field-name-field-video-embed {
			margin-top: -1rem;
		}
	}
	.business-logo-wrapper {
		padding: 0 0 0 0;
		img {
			width: 100%;
			height:auto;
		}
	}
	.field-name-field-video-embed {
		margin: 0 0 1rem;
		.video-embed-description {

		}
	}
	.field-name-field-video-headline {

	}
	.field-name-field-url,
	.ttd-related-items  {
		a {
			color: #fff;
			font-weight: 600;
		}
	}
	.ttd-related-items {
		display: block;
		a {
			&:after {
				content: ', ';
			}
			&:last-child:after {
				content: '';
			}
		}
	}
}

@mixin ttd-view-full-group {
	[class*="group-"] {
		@include ttd-view-full-fields;
	}
	.group-header {
		@include flex(100%);
	}
	.group-left {
		background: #fff;
		position: relative;
		z-index: 2;
		@include flex(100%);
		@include media($medium) {
			@include flex(5);
			margin: -2rem 0 0 1rem;
		}
		@include media($large) {
			margin: -3rem 0 0 1rem;
			padding: 1rem 1rem 0;
		}
	}
	.group-right {
		background: $primary-color;
		color: #fff;
		margin: 1rem;
		a {
			color: #fff;
		}
		@include flex(100%);
		@include media($medium) {
			@include flex(5);
		}
	}
}

@mixin ttd-view-full-header {
	main.outer-wrapper {
		padding-top: 0;
	}
	#content {
		position: relative;
	}
	#page-title {
		position: absolute;
		top: 4rem;
		left: 0;
		z-index: 2;
		color: #fff;
		width: 100%;
		font-size: 1.6rem;
		font-weight: 900;
		padding: 0 1rem 0;
		margin: 0;
		text-shadow: 0 0 10px rgba(0,0,0,.75);
		@include media($medium) {
			top: 8rem;
			font-size: 2rem;
			padding: 0 2rem 0;
		}
		@include media($large) {
			font-size: 3rem;
			text-align: center;
			padding: 0 3rem 0;
		}
	}
}

@mixin ttd-view-full {
	.vocabulary-things-to-do-categories.view-mode-full,
	.node-thing-to-do.view-mode-full {
		position: relative;
		@include display(flex);
		@include flex-flow(row wrap);
		@include ttd-view-full-group;
		@include ttd-view-full-header;
	}
}

@mixin ttd-view-full-views {
	.view-things-to-do.view-display-id-page {
		margin: 1rem;
	}
}

@mixin ttd-view-full-category-layout {
	#sidebar-content {
		margin: 0 1rem;
	}
	.term-listing-heading {
		@include media($medium) {
			margin: 0 0 2rem;
			@include flex(1 1 100%);
		}
	}
}

@mixin ttd-view-full-category {
	@include ttd-view-full-category-layout;
}

// Things to do teaser view
@mixin ttd-view-teaser-fields {
	.field-name-field-teaser-image {
		margin: 0;
	}
}

@mixin ttd-view-teaser-group {
	.group-header {
		@include flex(100%);
	}

	.group-left {
		background: #fff;
		@include flex(100%);
		@include media($medium) {
			@include flex(5);
		}
		@include media($large) {
			@include flex(4);
		}
	}

	.group-right {
		@include flex(100%);
		@include media($medium) {
			@include flex(5);
		}
		@include media($large) {
			@include flex(6);
		}
	}
}

@mixin ttd-view-teaser {
	.node-thing-to-do.view-mode-teaser {
		background: #fff;
		@include display(flex);
		@include flex-flow(row wrap);
		@include ttd-view-teaser-group;
		@include ttd-view-teaser-fields;
	}
}

@mixin ttd-view-teaser-views {
	.view-things-to-do.view-display-id-page {
		@include media($large) {

		}
		.view-header {
			margin: 0 0 1rem;
		}
		.views-row {
			margin: 0 0 2rem;
		}
	}
}

@mixin ttd-view-teaser-category-layout {
	&.no-sidebars section#content {
		@include display(flex);
		@include flex-flow(column nowrap);
		@include media($medium) {
			@include flex-flow(row wrap);
		}
	}
	#page-title {
		@include media($medium) {
			// Tan.7.14.16: Bug in safari - https://bugs.webkit.org/show_bug.cgi?id=136041
			@include flex(1 0 101%);
		}
	}
	.view {
		@include media($medium) {
			@include flex(6);
		}
	}
	#sidebar-content {
		@include media($medium) {
			@include flex(2);
			@include align-self(flex-start);
			margin-left: 1rem;
		}
	}
}

@mixin ttd-view-teaser-sidebar {
	.sidebar {
		background: #fff;
		h2 {
			padding: 1rem;
			background: $primary-color;
			color: #fff;
			margin: 0;
			text-transform: uppercase;
		}
		.menu {
			padding: .5rem 1rem 1rem;
		}
	}
}

@mixin ttd-view-teaser-category {
	@include ttd-view-teaser-category-layout;
	@include ttd-view-teaser-sidebar;
}

// Taxonomy and detail pages
.page-taxonomy-term,
.node-type-thing-to-do {
	@include ttd-view-full-header;
	@include ttd-view-full;
	@include ttd-view-full-views;
	@include ttd-view-full-category;
}

// Teaser nodes
.ttd-content {
	@include ttd-view-teaser;
	@include ttd-view-teaser-views;
	@include ttd-view-teaser-category;
}
