$base-spacing: 1.5em !default;
$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;
$message-border-radius: 5px;

@mixin flash($color) {
  background-color: $color;
  color: darken($color, 60%);
  display: block;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 2 $base-spacing / 2 $base-spacing / 2 50px;
  border-radius: $message-border-radius;

  a {
    color: darken($color, 70%);
    text-decoration: underline;

    &:focus,
    &:hover {
      color: darken($color, 90%);
    }
  }
}

.messages {
  &.error {
    @include flash($error-color);
  }

  &.status {
    @include flash($success-color);
  }

  &.notice {
    @include flash($notice-color);
  }

  &.warning {
    @include flash($alert-color);
  }
}
