// Blog styles

// Blog teaser
@mixin blog-view-teaser-fields {	
	.field-name-field-teaser-image {
		margin: 0;
	}
}

@mixin blog-view-teaser-group {
	.group-header {
		@include flex(100%);
	}

	.group-left {
		background: #fff;
		@include flex(100%);
		@include media($medium) {
			@include flex(5);
		}
		@include media($large) {
			@include flex(4);
		}
	}

	.group-right {
		@include flex(100%);
		@include media($medium) {
			@include flex(5);
		}
		@include media($large) {
			@include flex(6);
		}
	}
}

@mixin blog-view-teaser {
	.node-blog.view-mode-teaser {
		background: #fff;
		@include display(flex);
		@include flex-flow(row wrap);
		@include blog-view-teaser-fields;
		@include blog-view-teaser-group;
	}
}

@mixin blog-view-teaser-views {
	.view-blog.view-display-id-page {
		@include media($large) {

		}
		.view-header {
			margin: 0 0 1rem;
		}
		.views-row {
			margin: 0 0 2rem;
		}
	}
}


@mixin blog-view-teaser-category-description-group {
	[class*="group-"] {
		@include ttd-view-full-fields;
	}
	.group-header {
		@include flex(100%);
	}
	.group-left {
		background: #fff;
		position: relative;
		z-index: 2;
		@include flex(100%);
		@include media($medium) {
			@include flex(5);
			margin: -2rem 0 0 1rem;
		}
		@include media($large) {
			margin: -3rem 0 0 1rem;
			padding: 1rem 1rem 0;
		}
		&:empty,
		&:blank {
			background: transparent;
		}
	}
	.group-right {
		background: $primary-color;
		color: #fff;
		margin: 1rem;
		@include flex(100%);
		@include media($medium) {
			@include flex(5);
		}
	}
}

@mixin blog-view-teaser-category-description-header {
	main.outer-wrapper {
		padding-top: 0;
	}
	#content {
		position: relative;
	}
	#page-title {
		position: absolute;
		top: 4rem;
		left: 0;
		z-index: 2;
		color: #fff;
		width: 100%;
		font-size: 1.6rem;
		font-weight: 900;
		padding: 0 1rem 0;
		margin: 0;
		text-shadow: 0 0 10px rgba(0,0,0,.75);
		@include media($medium) {
			top: 8rem;
			font-size: 2rem;
			padding: 0 2rem 0;
		}
		@include media($large) {
			font-size: 3rem;
			text-align: center;
			padding: 0 3rem 0;
		}
	}
}

@mixin blog-view-teaser-category-description {
	.vocabulary-blog-categories.view-mode-full {
		position: relative;
		@include display(flex);
		@include flex-flow(row wrap);
		@include blog-view-teaser-category-description-group;
		@include blog-view-teaser-category-description-header;
	}
}


@mixin blog-view-teaser-category-layout {
	&.no-sidebars section#content {
		@include display(flex);
		@include flex-flow(column nowrap);
		@include media($medium) {
			@include flex-flow(row wrap);
		}
	}
	#page-title {
		@include flex(100%);
	}
	.term-listing-heading {
		@include media($medium) {
			margin: 0 0 2rem;
			@include flex(1 1 100%);
		}
	}
	.view-blog,
	.node-blog {
		@include media($medium) {
			margin: 0 1rem 2rem 0;
			@include flex(none);
			@include flex-basis(74%);
		}
		@include media($medium) {
			@include order(3)
		}
	}
	.term-listing-heading + .node-blog {
		@include media($medium) {
			@include flex(3 3 36%);
			@include order(1);
		}
	}
	#sidebar-content {
		margin: 0;
		@include media($medium) {
			@include flex(2);
			@include align-self(flex-start);
			@include order(2);
			margin: 0;
		}
	}
	.pagination-links {
		@include media($medium) {
			@include flex(100%);
			@include order(4);
		}
	}
	.view-blog {
		@include media($medium) {
			@include order(1);
		}
	}
}

@mixin blog-view-teaser-sidebar {
	.sidebar {
		background: #fff;
		h2 {
			padding: 1rem;
			background: $primary-color;
			color: #fff;
			margin: 0;
			text-transform: uppercase;
		}
		.menu {
			padding: .5rem 1rem 1rem;
		}
	}
}

@mixin blog-view-teaser-category {
	@include blog-view-teaser-category-description;
	@include blog-view-teaser-category-layout;
	@include blog-view-teaser-sidebar;
}


// Blog full view
@mixin blog-view-full-fields {	
	.blog-info-bar {
		border: solid #eee;
		border-width: 0 0 1px;
		margin: 0 0 1rem;
		font-size: 1rem;
		color: #666;
	}
	.blog-related-items {
		padding: 0 1rem 1rem;
		.label-inline {
			display: inline-block;
		}
		a {
			&:after {
				content: ', ';
			}
			&:last-child:after {
				content: '';
			}
		}
	}
}

@mixin blog-view-full-category-layout {
	.node-blog {
		@include media($medium) {
			@include order(1);
		}
	}
}

@mixin blog-view-full-sidebar {

}

@mixin blog-view-full-category {
	@include blog-view-full-category-layout;
	@include blog-view-full-sidebar;
}

@mixin blog-view-full {
	.node-blog.view-mode-full {
		background: white;
		@include blog-view-full-fields;
	}
}

// Teaser blog
.page-blog {
	@include blog-view-teaser;
	@include blog-view-teaser-views;
	@include blog-view-teaser-category;
}

// Full blog
.page-node.node-type-blog {
	@include blog-view-full;
	@include blog-view-full-category
}