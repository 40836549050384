// Homepage Styling
.front {
    .marinas-portal {
        position: relative;
        z-index: 2;
        @include media($medium) {
        	margin-top: -5rem;
        }
    }
}

// Map
.map {
    .marinas-map-container {
    	.marinas-map {
    		width: 100%;
    	}
    }
}
