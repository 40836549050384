.breadcrumb {
  ///////////////////////////////////////////////////////////////////////////////////
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $base-spacing: 1.5em !default;
  $base-accent-color: #477DCA !default;
  $base-link-color: $base-accent-color !default;
  $medium-gray: #999 !default;
  $light-gray: #DDD !default;
  $medium-screen: em(640) !default;
  //////////////////////////////////////////////////////////////////////////////////

  $breadcrumb-border-color: $base-border-color;
  $breadcrumb-border: 1px solid $breadcrumb-border-color;
  $breadcrumb-height: $base-spacing * 1.5;
  $breadcrumb-arrow-color: $breadcrumb-border-color;
  $breadcrumb-background: $base-background-color;
  $breadcrumb-inactive-hover-color: $breadcrumb-background;
  $breadcrumb-color: $medium-gray;
  $breadcrumb-color-hover: $base-accent-color;
  $breadcrumb-color-active: $breadcrumb-color;

  display: inline-block;
  margin-bottom: $base-spacing;
  text-align: left;

  a {
    background-color: $breadcrumb-background;
    border: $breadcrumb-border;
    color: $breadcrumb-color;
    display: inline-block;
    font-size: 0.8em;
    line-height: $breadcrumb-height;
    margin-bottom: 2px;
    margin-right: -5px;
    padding: 0 ($breadcrumb-height / 4) 0 ($breadcrumb-height / 2);
    position: relative;
    text-decoration: none;

    &:first-child {
      border-bottom-left-radius: $base-border-radius;
      border-left: $breadcrumb-border;
      border-top-left-radius: $base-border-radius;
      padding-left: $breadcrumb-height / 2;
    }

    &:last-child {
      background-color: $breadcrumb-background;
      border-bottom-right-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
      color: $breadcrumb-color-active;
      padding-right: $breadcrumb-height / 2;
      border-left: none;
    }

    &:focus,
    &:hover {
      background-color: $breadcrumb-inactive-hover-color;
      color: $breadcrumb-color-hover;
    }

    &:after,
    &:before {
      @include position(absolute, 0px auto 0px 100%);
      border-bottom: $breadcrumb-height / 2 solid transparent;
      border-left: $breadcrumb-height / 4 solid transparent;
      border-top: $breadcrumb-height / 2 solid transparent;
      content: '';
      display: block;
      margin: auto;
      z-index: 2;
    }

    &:last-child:after,
    &:last-child:before {
      border: none;
    }

    &:before {
      border-left-color: $breadcrumb-arrow-color;
      margin-left: 1px;
      z-index: 1;
    }
    &:after {
      border-left-color: $breadcrumb-background;
    }

    @include media($medium-screen) {
      font-size: 1em;
      padding: 0 ($breadcrumb-height / 2) 0 ($breadcrumb-height / 1.5);
    }
  }
}

// Disable front-end breadcrubs
#breadcrumb {
  display: none;
}
