// Global field styles
@mixin field-global {
	padding: 0 1rem;
	margin: 0 0 .5rem;
	&:first-child {
		padding: 1rem 1rem 0;
	}
	&:last-child {
		padding: 0 1rem 1rem;
		margin: 0;
	}
}

@mixin field-snippet {
	.field-name-field-basic-page-snippet-title {
		font-size: 1.6rem;
		margin: 0 0 .5rem;
		text-align: center;
		@include media($medium) {
			font-size: 2rem;
			text-align: center;
			margin: 1rem 0 2rem;
		}
	}
	.field-name-field-basic-page-snippet-content {
		.item-list > ul{
			@include display(flex);
			@include flex-flow(row wrap);
		}
		.item-list > ul > li {
			margin: 1rem;
			@include flex(1);
			@include flex-basis(100%);
			@include media($medium) {
				@include flex-basis(25%);
				margin: 0 1rem 1rem 0;
			}
		}
		.node-snippet {
			background: $primary-color;
			h2 {
				margin: 1rem 0;
				color: #fff;
			}
		}
	}
}

@mixin field-info {
	.field-name-post-date {
		@include field-global;
	}
	.field-name-field-location {
		@include field-global;
	}
	.field-name-node-link {
		@include field-global;
		a {
			@extend button;
		}
	}
	.field-name-field-phone-nums {
		@include field-global;
	}
	.field-name-field-url {
		@include field-global;
	}
	.ttd-related-items {
		@include field-global;
	}
	.node-webform {
		.posted {
			display: none;
		}
	}
}

@mixin field-headings {
	[class*="-headline"],
	[class*="-name-title"] {
		padding: 0 1rem;
		margin: 0 0 .5rem;
		font-weight: 600;
		text-transform: uppercase;
		&:first-child {
			margin-top: 1rem;
		}
	}
	.field-name-title {

	}
	.field-name-field-term-headline {

	}
	.field-name-field-video-headline {

	}
}

@mixin field-descriptions {
	[class*="-description"],
	[class*="-name-body"] {
		padding: 0 1rem 1rem;
	}
	.field-name-field-video-description {

	}
	.field-name-body {

	}
}

@mixin field-image {
	[class*="-image"] {
		img {
    		width: 100%;
			display: block;
			height: auto;
		}
	}
	.field-name-field-banner-image {

	}
	.field-name-field-teaser-image {
		margin: 0 0 1rem;
		a {
			display: block;
		}
	}
	.field-name-field-gallery-images {
		padding: 1rem;
		img {
			margin: 0 0 1rem;
		}
	}
	.field-name-field-sidebar-image {
		margin: 0 0 1rem;
		img {
			width: 100%;
		}
	}
}

@mixin field-video {
	.field-name-field-video-embed {
		margin: 0 0 1rem;
		.player {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56%;
			overflow: hidden;
		}
		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		.video-embed-description {
			text-transform: uppercase;
			font-size: .8rem;
			padding: .5rem 1rem;
			background: white;
			color: #666;
		}
	}
}

@mixin fields {
	@include field-snippet;
	@include field-info;
	@include field-headings;
	@include field-descriptions;
	@include field-image;
	@include field-video;
}