// Stylefixes for when Drupal admin menu is shown
body.admin-menu {
	.top-bar {
		margin-top: 20px !important
	}
}

body {
	background: #EDF3F2
}

// Define base layout, order, etc for page template
.page {
	position: relative;
}

.node-type-basic-page {
	.group-body-content {
		max-width: 46rem;
		margin: auto;
	}
	&.not-front {
		.group-body-content {
			background: white;
			padding: 1rem 0;
			margin: 0 auto 2rem;
			@include media($medium) {
				padding: 1rem 2rem;
				margin: -5rem auto 3rem;
			}
		}
		#banner-wrapper {
			max-width: 100%;
		}
	}
}

// Editing tabs
.tabs.primary {
	position: fixed;
	z-index: 2;
	bottom: 0;
	right: 0;
	margin: 0;
}

// Forms
.webform-client-form {
  padding: 1rem;
  @include media($medium) {
    padding: 0;
  }
}

// Webcam
iframe[src*="obxcams.com"] {
	margin: 0 auto;
	display: block;
}